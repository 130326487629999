import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          icon: "pe-7s-bandaid",
          title: "Primary Care Telemedicine",
          desc:
            "RediMD is revolutionizing the way we treat all primary care conditions. Our team of professionals are available 24/7 to provide you with an array of services, including prescribing medicines and refills, ordering bloodwork tests or imaging scans if needed, as well as referrals - whatever it takes to get your health back on track! Conveniently online or over the phone anytime day or night.",
        },
        {
          icon: "pe-7s-tools",
          title: "Workers Compensation Telemedicine",
          desc:
            "We are one of the largest workers compensation telemedicine providers in the United States providing services to our domestic clients in the United States, Canada, and Mexico. Our clients represent a cross-section of private and public sector industries from large petro-chemical and transportation companies to school district and county entities. The services we provide to our clients are not solely limited to telemedicine, but also include staffing, consulting, fit for duty, and return to work strategies.",
        },
        {
          icon: "pe-7s-like",
          title: "Mental Health and Chronic disease Management",
          desc:
            "Through our Guardian and EAP services, we offer specialized health care to help manage mental well-being and chronic medical conditions. Our commitment is dedicated to maintaining your personal wellness journey with a comprehensive approach of support.",
        },
      ],
      services2: [
        {
          icon: "pe-7s-science",
          title: "Awesome Support",
          desc:
            "It is a paradisematic country, in which roasted parts of sentences fly into your mouth leave for the far World.",
        },
        {
          icon: "pe-7s-news-paper",
          title: "Truly Multipurpose",
          desc:
            "Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic.",
        },
        {
          icon: "pe-7s-plane",
          title: "Easy to customize",
          desc:
            "Question Marks and devious Semikoli, but the Little Blind Text didn’t listen. She packed her seven versalia.",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={"section " + this.props.sectionClass} id="services">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Our Healthcare Services"
              desc="RediMD has been leading telemedicine with its comprehensive, reliable telemedicine services since 2008. Our innovative approach has allowed us to become one of the largest providers in the country and accumulate hundreds of thousands of registered patients and millions of patients have this benefit on their plan. While winning multiple awards along the way!"
            />

            <Row className="mt-4">
              {/* services box */}
              <ServiceBox services={this.state.services1} />
            </Row>

            {/* <Row className="mt-4">
              <ServiceBox services={this.state.services2} />
            </Row> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
